import React from 'react'

const CouchbaseShell = () => {
  return (
    <div className="page--couchbase-shell m-8 mb-12 wrapper">
      <h1>Couchbase Shell</h1>
      <p>
        The best command-line tool for accessing Couchbase is the new Couchbase Shell, which brings data loading, saving,
        accessing and querying, along with cluster administration and convenient slice-and-dice JSON operations to your fingertips.
      </p>
      <p>
        <a target="_blank" rel="noopener noreferrer" href="https://couchbase.sh/">https://couchbase.sh/</a>
      </p>
    </div>
  )
}
export default CouchbaseShell